import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import colors from "../constants/colors";

const STANDBY_BLUE = colors.treatmentStatus.standby;

// styled components
const StyledIconButton = styled(Button)`
  height: 2.25rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  font-weight: bold;
  text-align: center;
  font-size: 1em;
  color: ${(props) => props.customcolor};

  &:hover {
    background-color: inherit;
  }
`;

const StyledStartIcon = styled(AddCircleOutlineOutlinedIcon)`
  font-size: 1.25em;
  text-align: center;
`;

/**
 * Generic Icon Button Component, with flexible icon/styling API to suit less common button types
 *
 * @param {object} props
 * @param {string|Function} props.children Main button content
 * @param {React.Element} [props.startIcon = <StyledStartIcon>] An icon to appear on the left of the button content, defaults to the add icon
 * @param {string} [props.color = "text"] MUI's color prop, references the current MUI theme
 * @param {string} [props.customcolor = STANDBY_BLUE] A hex value color for the button text, defaults to a color constant
 * @param {Function} props.onClick The click handler for this button
 * @param {string} [props.variant = "text"] MUI uses this to set button type, e.g. "outlined"
 * @param {string} [props.size = "medium"] MUI can set button to small, medium, or large
 * @returns {React.Element}
 */
const IconButton = ({
  children,
  onClick,
  startIcon,
  color,
  customcolor,
  variant,
  size,
}) => (
  <StyledIconButton
    startIcon={startIcon}
    onClick={onClick}
    color={color}
    customcolor={customcolor}
    variant={variant}
    size={size}
  >
    {children}
  </StyledIconButton>
);

IconButton.propTypes = {
  customcolor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string,
  startIcon: PropTypes.node,
  variant: PropTypes.string,
};

IconButton.defaultProps = {
  color: "default",
  customcolor: STANDBY_BLUE,
  onClick: () => {},
  size: "medium",
  startIcon: <StyledStartIcon />,
  variant: "text",
};

export default IconButton;
