import React, { useCallback } from "react";
import { AppBar, Toolbar, Box } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import styled from "styled-components";
import logo from "../assets/tx-scheduling-logo.png";
import BlueButton from "./BlueButton";
import GreenButton from "./GreenButton";

// styled components
const CustomNavBar = styled(AppBar)`
  background-color: white;
  height: 3.75rem;
`;

const CustomToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
  height: 100%;
`;

const LogoStyle = styled.img`
  width: 12rem;
  height: 3rem;
  cursor: pointer;
`;

const FlexRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

/**
 * Navbar Header Component
 *
 * @returns {React.Element} An auth-sensitive component that displays the logout button to logged-in users
 */
const HeaderBar = () => {
  const { keycloak } = useKeycloak();

  // logout function
  const logout = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  return (
    <CustomNavBar position="sticky">
      <CustomToolbar>
        <LogoStyle src={logo} />
        <FlexRow>
          {keycloak?.authenticated && (
            <GreenButton
              color="secondary"
              type="button"
              variant="outlined"
              onClick={logout}
            >
              Log Out
            </GreenButton>
          )}
          <BlueButton color="primary" variant="outlined">
            Contact Support
          </BlueButton>
        </FlexRow>
      </CustomToolbar>
    </CustomNavBar>
  );
};

export default HeaderBar;
