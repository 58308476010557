import React from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import PrivateRoute from "./PrivateRoute";
import styled from "styled-components";
import { Box } from "@material-ui/core";

// Components
import LoginPage from "../pages/Login";
import PatientSearch from "../pages/PatientSearch";
import NewAppointmentPage from "../pages/NewAppointment";
import NewPatientPage from "../pages/NewPatient";
import CircularProgress from "@material-ui/core/CircularProgress";
import HeaderBar from "../components/HeaderBar";
import FooterBar from "../components/FooterBar";

// Utilities
import * as routes from "../constants/routes";

// styled components
const LoadingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

/**
 * Main application router
 *
 * @returns {React.Element} The app router.
 */
const AppRouter = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  return (
    <Router>
      <Switch>
        <Route
          path={routes.login}
          component={() => (
            <>
              <HeaderBar />
              <LoginPage />
              <FooterBar />
            </>
          )}
        />
        <PrivateRoute
          exact
          path={routes.patientSearch}
          component={PatientSearch}
        />
        <PrivateRoute
          exact
          path={routes.appointmentNew}
          component={NewAppointmentPage}
        />
        <PrivateRoute path={routes.patientsNew} component={NewPatientPage} />
        <Redirect exact from="/" to={routes.login} />
        <Route path={routes.login} component={LoginPage} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
