import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import colors from "../constants/colors";

// styled components
const CustomButton = styled(Button)`
  background-color: ${colors.offwhite};
  height: 2.5rem;
  width: ${(props) => props.width}rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  margin-right: ${(props) => (props.buttontype !== null ? "0px" : "1rem")};
  margin-left: ${(props) => (props.buttontype !== null ? "2rem" : "0px")};
  font-weight: bold;
  text-align: center;
  font-size: 1em;
  flex-shrink: 0;
  color: ${(props) => !props.disabled && "#2962FF"};

  &.MuiButton-contained.Mui-disabled {
    border: 2px solid grey;
  }

  &.MuiButton-outlined.Mui-disabled {
    border: 2px solid rgba(0, 0, 0, 0.12);
  }
`;

/**
 * Blue Button Component, wraps MUI's Button
 *
 * @param {object} props
 * @param props.color
 * @param props.variant
 * @param props.children
 * @param props.onClick
 * @param props.isDisabled
 * @param props.buttontype
 * @param props.startIcon
 * @returns {React.Element}
 */

const BlueButton = ({
  color,
  variant,
  children,
  onClick,
  isDisabled,
  buttontype,
  startIcon,
  width,
}) => (
  <CustomButton
    color={color}
    variant={variant}
    onClick={onClick}
    disabled={isDisabled}
    buttontype={buttontype}
    startIcon={startIcon}
    width={width}
  >
    {children}
  </CustomButton>
);

BlueButton.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  buttontype: PropTypes.string,
  startIcon: PropTypes.node,
  width: PropTypes.number,
};

BlueButton.defaultProps = {
  isDisabled: false,
  onClick: () => {},
  buttontype: null,
  startIcon: null,
  width: 12,
};

export default BlueButton;
