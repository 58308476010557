import React from "react";
import styled from "styled-components";
import logo from "../assets/tas-logo.png";
import { Box } from "@material-ui/core";

// styled components
const FooterText = styled(Box)`
  width: 100vw;
  text-align: center;
  color: white;
  font-size: 0.8rem;
  position: fixed;
  bottom: 1rem;
`;

const StyledFooter = styled(Box)`
  width: 100%;
  height: 3.375rem;
  postion: fixed;
  bottom: 0;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const LogoStyle = styled.img`
  width: 10rem;
  cursor: pointer;
`;

/**
 * Footer Component
 *
 * @returns {React.Element}
 */

const FooterBar = () => {
  // renders the footer text only if the login page is the current route
  if (window.location.pathname === "/login") {
    return <FooterText>TRANSLATIONAL ANALYTICS & STATISTICS</FooterText>;
  }
  return (
    <StyledFooter>
      <LogoStyle src={logo} />
    </StyledFooter>
  );
};

export default FooterBar;
