import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateFnsUtils from "@date-io/date-fns";
import colors from "../constants/colors";
import FieldLabel from "./FieldLabel";
import "date-fns";

// styled components
const CustomDatePicker = styled(KeyboardDatePicker)`
  background-color: ${colors.white};
  width: 17rem;
  border-radius: 8;
  border-color: ${colors.grey.light};

  .MuiInput-root.Mui-focused {
    border-width: 1px;
  }
  .MuiInputBase-root {
    color: black;
    fontfamily: "Lato";
  }
  .MuiIconButton-root:hover {
    background-color: transparent;
  }
  .MuiIconButton-root {
    padding: 6px;
  }
`;

const CalendarIcon = styled(DateRangeIcon)`
  font-size: 0.75em;
  color: black;
  text-align: center;
`;

/**
 * Date Picker Component
 *
 * @param {object} props
 * @param props.dateState
 * @param props.type
 * @param props.label
 * @param props.dateChange
 * @returns {React.Element}
 */
const DatePicker = ({ dateState, type, label, dateChange }) => {
  const handleDateChange = (e) => {
    dateChange(e, type);
  };

  return (
    <FieldLabel
      control={
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CustomDatePicker
            InputProps={{
              disableUnderline: true,
            }}
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={dateState}
            onChange={handleDateChange}
            keyboardIcon={<CalendarIcon />}
          />
        </MuiPickersUtilsProvider>
      }
      label={label}
      labelPlacement="start"
    />
  );
};

DatePicker.propTypes = {
  dateState: PropTypes.instanceOf(Date),
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dateChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  dateState: null,
};

export default DatePicker;
