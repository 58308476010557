import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControlLabel } from "@material-ui/core";
import colors from "../constants/colors";

// styled components
const StyledFieldLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    flex-shrink: 0;
    color: ${colors.grey.mediumMedium};
    width: ${(props) => `${props.labelwidth}rem`};
    text-align: left;
    font-size: 0.8rem;
    margin-left: 0;
    margin-bottom: ${(props) => props.labelPlacement === "top" && `0.875rem`};
  }
  & .MuiFormControlLabel-root {
    justify-content: "flex-end";
    width: 100%;
  }
  & .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

/**
 * Field Label Component
 *
 * @param {object} props
 * @param props.label
 * @param props.labelPlacement
 * @param props.control
 * @param props.style
 * @param props.labelwidth
 * @returns {React.Element}
 */
const FieldLabel = ({ label, labelPlacement, control, labelwidth, style }) => (
  <StyledFieldLabel
    control={control}
    label={label}
    style={style}
    labelPlacement={labelPlacement}
    labelwidth={labelwidth}
  />
);

FieldLabel.propTypes = {
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  labelwidth: PropTypes.number,
  control: PropTypes.element.isRequired,
  style: PropTypes.shape(),
};

FieldLabel.defaultProps = {
  label: "",
  labelPlacement: "end",
  labelwidth: 10,
  style: { marginLeft: 0 },
};

export default FieldLabel;
