import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import colors from "../constants/colors";
import FieldLabel from "./FieldLabel";

// styled components
const CustomDrop = styled(FormControl)`
  background-color: ${colors.white};
  width: ${(props) => (props.width === null ? "17rem" : `${props.width}`)};
  border-radius: 8px;
  border-color: ${colors.grey.light};

  .MuiInput-root.Mui-focused {
    border-width: 1px;
  }
`;

const CustomSelect = styled(Select)`
  font-size: 1rem;
`;

/**
 * Dropdown Component
 *
 * @param {object} props
 * @param props.menuItems
 * @param props.dropdownType
 * @param props.currentValue
 * @param props.label
 * @param props.onChange
 * @param props.style
 * @param props.customWidth
 * @returns {React.Element}
 */
const DropDown = ({
  menuItems,
  dropdownType,
  currentValue,
  label,
  onChange,
  customWidth,
  style = { fontSize: "1rem" },
}) => {
  const handleMenuChange = (e) => {
    onChange(e, dropdownType);
  };
  return (
    <FieldLabel
      control={
        <CustomDrop width={customWidth}>
          <CustomSelect
            value={currentValue}
            onChange={handleMenuChange}
            disableUnderline
            style={style}
          >
            {menuItems.map((val) => (
              <MenuItem key={`menu${val}`} value={val} style={style}>
                {val}
              </MenuItem>
            ))}
          </CustomSelect>
        </CustomDrop>
      }
      label={label}
      labelPlacement="start"
    />
  );
};

DropDown.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  dropdownType: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  customWidth: PropTypes.string,
  style: PropTypes.shape(),
};

DropDown.defaultProps = {
  customWidth: null,
  style: null,
};

export default DropDown;
