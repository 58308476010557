import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Typography, Box } from "@material-ui/core";
import BlueButton from "../../components/BlueButton";
import DropDown from "../../components/DropDown";
import DatePicker from "../../components/DatePicker";
import TimePicker from "../../components/TimePicker";
import AlternatingTabButton from "../../components/AlternatingTabButton";
import TextInput from "../../components/TextInput";
import RadioButton from "../../components/RadioButton";
import BreadCrumbs from "../../components/BreadCrumbs";
import ProcedureForm from "../../components/ProcedureForm";
import TextArea from "../../components/TextArea";
import AdditionButton from "../../components/AdditionButton";

// styled components
const Wrapper = styled.form`
  min-height: calc(100vh - 7.125rem);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
  padding-bottom: 3rem;
`;

const FlexStartRow = styled(Box)`
  position: relative;
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

const FlexEndRow = styled(Box)`
  position: relative;
  width: 95%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`;

const HeaderLarge = styled(Typography)`
  font-family: "Lato";
  text-align: center;
  color: #333333;
  font-size: 1.5rem;
`;

const HeaderSmall = styled(Typography)`
  font-family: "Lato";
  text-align: center;
  color: #5c5c5c;
  font-size: 1.1rem;
`;

const LargeSpacer = styled(Box)`
  width: 6rem;
  height: 1px;
  background-color: transparent;
`;

const SmallSpacer = styled(Box)`
  width: 2rem;
  height: 1px;
  background-color: transparent;
`;

const VerticalSpacer = styled(Box)`
  width: 1px;
  height: 1.5rem;
  background-color: transparent;
`;

const Divider = styled(Box)`
  position: relative;
  width: 95%;
  height: 0.1rem;
  background-color: rgba(128, 194, 131, 0.88);
  margin-top: 1.25rem;
`;

const DescriptionText = styled(Typography)`
  font-size: 0.75rem;
  color: #5c5c5c;
  text-align: left;
`;

const ProcedureFormBox = styled(Box)`
  position: relative;
  width: 100%;
  height: fit-content;
`;

/**
 * Scheduling New Appointment page and all corresponding UI elements.
 *
 * @returns {React.Element}
 *
 */

const NewAppointmentPage = () => {
  const history = useHistory();

  const procedureRef = useRef(null);
  const ROUTES = ["Route 1", "Route 2", "Route 3"];
  const [currentRoute, setCurrentRoute] = useState("");

  const FACILITIES = ["Facility 1", "Facility 2", "Facility 3"];
  const [currentFacility, setCurrentFacility] = useState("");

  const [serviceLocation, setServiceLocation] = useState("");

  const [visitDate, setVisitDate] = useState(null);
  const [visitStartTime, setVisitStartTime] = useState(null);

  const [alternatingButtonCheck, setAlternatingButtonCheck] = useState(false);
  const [appointmentStatus, setAppointmentStatus] = useState(null);
  const [contactDate, setContactDate] = useState(null);
  const [appointmentNotes, setAppointmentNotes] = useState("");

  const PHYSICIANS = ["Physician 1", "Physician 2", "Physician 3"];
  const [currentPhysician, setCurrentPhysician] = useState(PHYSICIANS[0]);

  const [procedures, setProcedures] = useState([
    {
      "Procedure 1": {
        procedure: "",
        endTime: null,
        alternatingButtonCheck: false,
      },
    },
  ]);

  // useEffect fired only if the dayPeriod state changes - this is to add or subtract 12 hours to a selected time if the AM or PM button has been clicked
  useEffect(() => {
    if (visitStartTime != null) {
      let dateObj = new Date(visitStartTime);
      const dayPeriod = !alternatingButtonCheck ? "AM" : "PM";
      const currentHour = dateObj.getHours();

      // current time is in the PM and the AM button is clicked - subtract 12 hours from that time
      if (currentHour >= 12 && dayPeriod === "AM") {
        dateObj = dateObj.setHours(dateObj.getHours() - 12);
      }
      // current hour is in the AM and the PM button is clicked - add 12 hour to that time
      else if (currentHour < 12 && dayPeriod === "PM") {
        dateObj = dateObj.setHours(dateObj.getHours() + 12);
      }

      setVisitStartTime(dateObj);
    }
  }, [alternatingButtonCheck]);

  // function that handles the click events on the different dropdown components - uses switch/case to set the state properly for each one
  const handleChange = (event, type) => {
    switch (type) {
      case "routes":
        setCurrentRoute(event.target.value);
        break;
      case "facility":
        setCurrentFacility(event.target.value);
        break;
      case "physician":
        setCurrentPhysician(event.target.value);
        break;
      default:
        break;
    }
  };

  // function that handles the date change on different calendar components
  const handleDateChange = (date, type) => {
    switch (type) {
      case "visit":
        setVisitDate(date);
        break;
      case "contact":
        setContactDate(date);
        break;
      default:
        break;
    }
  };

  // function that handles the time change on the timepicker component
  const handleTimeChange = (date) => {
    setVisitStartTime(date);
  };

  // function that sets the time picker default time to the users current time when opened
  const getInitialTime = () => {
    let initialDate = new Date();
    initialDate.setHours(0, 0, 0, 0);
    const dayPeriod = !alternatingButtonCheck ? "AM" : "PM";

    // // if the PM button is pressed before the timepicker is opened, 12 hours are added to the time
    if (dayPeriod === "PM") {
      initialDate = initialDate.setHours(initialDate.getHours() + 12);
    }

    return initialDate;
  };

  // function that sets the state of the current time period (AM or PM) after a time is selected from the picker
  const handleTimeAccept = (date) => {
    const dateObj = new Date(date);
    const hour = dateObj.getHours();

    if (hour >= 12) {
      setAlternatingButtonCheck(true);
    } else {
      setAlternatingButtonCheck(false);
    }
  };

  // function that checks whether the Custom Procedure state form is valid or not
  const validateCustomProcedures = () => {
    let isInvalid = true;
    for (let i = 0; i < procedures.length; i += 1) {
      const currentKey = `Procedure ${parseInt(i, 10) + 1}`;
      const currentProcedure = procedures[i][currentKey];

      if (
        currentProcedure.endTime === null ||
        currentProcedure.procedure.length === 0
      ) {
        isInvalid = true;
        break;
      } else {
        isInvalid = false;
      }
    }

    return isInvalid;
  };

  // function that checks if all of the required input fields have values associated to them
  const validateInputs = () => {
    if (
      currentRoute.length > 0 &&
      visitDate != null &&
      currentPhysician != null &&
      currentFacility.length > 0 &&
      serviceLocation.length > 0 &&
      visitStartTime != null &&
      validateCustomProcedures() === false &&
      appointmentStatus != null &&
      contactDate != null
    ) {
      return false;
    }
    return true;
  };

  // function that allows routing to whatever page when called
  const cancelRoute = (path) => {
    history.push(path);
  };

  // scrolls to specific ref at the bottom of the ProcedureForm component
  const scrollToRef = () => {
    const refPosition = procedureRef.current.getBoundingClientRect();
    window.scrollTo({ top: refPosition.top, behavior: "smooth" });
  };

  // calls the scroll function after a new procedure has been created
  useEffect(() => {
    // prevents scroll on the first intial render
    if (procedures.length > 1) {
      scrollToRef();
    }
  }, [procedures.length]);

  return (
    <Wrapper>
      <FlexStartRow style={{ marginTop: "1rem" }}>
        <BreadCrumbs
          currentPage="New Appointment"
          currentPath="/appointments/new"
        />
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "2rem" }}>
        <HeaderLarge>New Appointment</HeaderLarge>
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "1.25rem" }}>
        <HeaderSmall>Patient Information</HeaderSmall>
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "1.5rem" }}>
        <TextInput
          label="Last Name"
          placeholder=""
          setValue={null}
          value="Stone"
          disabled
        />
        <LargeSpacer />
        <TextInput
          label="First Name"
          placeholder=""
          setValue={null}
          value="Lime"
          disabled
        />
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "0.5rem" }}>
        <TextInput
          label="Date of Birth"
          placeholder=""
          setValue={null}
          value="00/00/0000"
          disabled
        />
        <LargeSpacer />
        <TextInput
          label="Sex"
          placeholder=""
          setValue={null}
          value="Female"
          disabled
        />
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "0.5rem" }}>
        <TextInput
          label="Cell Phone"
          placeholder=""
          setValue={null}
          value="--"
          disabled
        />
        <LargeSpacer />
        <TextInput
          label="Email"
          placeholder=""
          setValue={null}
          value="--"
          disabled
        />
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "0.5rem" }}>
        <TextInput
          label="Insurance Provider"
          placeholder=""
          setValue={null}
          value="Blue Cross Blue Shield"
          disabled
        />
        <LargeSpacer />
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "0.5rem" }}>
        <TextInput
          label="Group #"
          placeholder=""
          setValue={null}
          value="--"
          disabled
        />
        <LargeSpacer />
        <TextInput
          label="Member ID"
          placeholder=""
          setValue={null}
          value="--"
          disabled
        />
      </FlexStartRow>
      <Divider />
      <FlexStartRow style={{ marginTop: "1.5rem" }}>
        <HeaderSmall>Visit Information</HeaderSmall>
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "2rem" }}>
        <DatePicker
          dateState={visitDate}
          type="visit"
          label="Date"
          dateChange={handleDateChange}
        />
        <LargeSpacer />
        <DropDown
          menuItems={FACILITIES}
          dropdownType="facility"
          currentValue={currentFacility}
          label="Facility"
          onChange={handleChange}
        />
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "2rem" }}>
        <DropDown
          menuItems={ROUTES}
          dropdownType="routes"
          currentValue={currentRoute}
          label="Route"
          onChange={handleChange}
        />
        <LargeSpacer />
        <TextInput
          label="Service Location"
          placeholder=""
          setValue={setServiceLocation}
          value={serviceLocation}
        />
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "2rem" }}>
        <DropDown
          menuItems={PHYSICIANS}
          dropdownType="physician"
          currentValue={currentPhysician}
          label="Physician"
          onChange={handleChange}
          customWidth="38rem"
        />
        <Box width="1rem" />
        <AdditionButton variant="text">Add New Physician</AdditionButton>
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "2rem" }}>
        <TimePicker
          timeState={visitStartTime}
          label="Start Time"
          onChange={handleTimeChange}
          onAccept={handleTimeAccept}
          initialFocusedDate={getInitialTime}
        />
        <SmallSpacer />
        <AlternatingTabButton
          tags={["AM", "PM"]}
          toggleHook={setAlternatingButtonCheck}
          checked={alternatingButtonCheck}
        />
      </FlexStartRow>
      <VerticalSpacer />
      {procedures.map((val, idx) => (
        <ProcedureFormBox key={`Procedure ${idx + 1}`}>
          <div ref={procedureRef} />
          <ProcedureForm
            procedureObject={procedures}
            procedureHook={setProcedures}
            index={idx}
          />
        </ProcedureFormBox>
      ))}
      <FlexStartRow style={{ marginTop: "1.5rem" }}>
        <RadioButton
          radioPlaceholder="Scheduled"
          labelText="Appointment Status"
          onClick={setAppointmentStatus}
          value={appointmentStatus}
        />

        <RadioButton
          radioPlaceholder="Confirmed"
          labelText={null}
          onClick={setAppointmentStatus}
          value={appointmentStatus}
        />

        <RadioButton
          radioPlaceholder="Add-On"
          labelText={null}
          onClick={setAppointmentStatus}
          value={appointmentStatus}
        />
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "1.5rem" }}>
        <DatePicker
          dateState={contactDate}
          type="contact"
          label="Date of Contact"
          dateChange={handleDateChange}
        />
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "1.5rem" }}>
        <DescriptionText>Appointment Notes & Requests</DescriptionText>
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "0.5rem" }}>
        <TextArea
          placeholder="Please indicate machine or technologist preference."
          value={appointmentNotes}
          setValue={setAppointmentNotes}
        />
      </FlexStartRow>
      <Divider />
      <FlexEndRow style={{ marginTop: "1.5rem" }}>
        <BlueButton
          color="primary"
          variant="outlined"
          buttontype="cancel"
          onClick={() => cancelRoute("/login")}
        >
          Cancel
        </BlueButton>
        <BlueButton
          color="primary"
          variant="contained"
          buttontype="submit"
          isDisabled={validateInputs()}
        >
          Save
        </BlueButton>
      </FlexEndRow>
    </Wrapper>
  );
};

export default NewAppointmentPage;
