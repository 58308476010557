import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import colors from "../constants/colors";
import { TextField } from "@material-ui/core";
import FieldLabel from "./FieldLabel";

// styled components
const CustomTextField = styled(TextField)`
  background-color: ${colors.white};
  width: ${({ inputwidth }) => `${inputwidth}rem`};
  border-radius: 8;
  border-color: ${colors.grey.light};

  .MuiInput-root.Mui-focused {
    border-width: 1px;
  }
  .MuiInput-root.Mui-disabled {
    background-color: white;
    color: black;
    border: none;
  }
  .MuiInput-input {
    padding-top: 0.5rem;
  }
`;

/**
 * Text Field Component
 *
 * @param {object} props
 * @param props.labelText
 * @param props.textFieldPlaceholder
 * @param props.setValue
 * @param props.value
 * @param props.inputwidth
 * @param props.disabled
 * @returns {React.Element}
 */

const TextInput = ({
  label,
  labelPlacement,
  labelwidth,
  placeholder,
  setValue,
  value,
  inputwidth,
  type,
}) => {
  const handleTextChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <FieldLabel
      control={
        <CustomTextField
          placeholder={placeholder}
          InputProps={{ disableUnderline: true }}
          inputwidth={inputwidth}
          style={{ width: label === "Extension" && "8rem" }}
          onChange={handleTextChange}
          value={value}
          disabled={setValue === null}
          type={type}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      labelwidth={labelwidth}
    />
  );
};

TextInput.propTypes = {
  inputwidth: PropTypes.number,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  labelwidth: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  type: PropTypes.string,
};

TextInput.defaultProps = {
  inputwidth: 17,
  label: "",
  labelPlacement: "start",
  labelwidth: 10,
  setValue: null,
  type: null,
};

export default TextInput;
