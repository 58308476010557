import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

// MUI
import {
  Button,
  Collapse,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Search as SearchIcon,
  People as PeopleIcon,
} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";

// Custom Components
import {
  BlueButton,
  GreenButton,
  IconButton,
  TextInput,
} from "../../components";

// Constants
import colors from "../../constants/colors";
import { appointmentNew, patientsNew } from "../../constants/routes";

const SEARCH_INPUT_WIDTH = 16.875; // 270px
const SECTION_PADDING = 2.8125; // 45px
const LIABILITY_BLUE = colors.status.patientLiability;
const SILVER = colors.silver;
const TAS_GREEN = colors.chart.green.medium;
const WHITESMOKE = colors.whitesmoke;

// Styled Components
const Wrapper = styled(Grid)`
  min-height: calc(100vh - 7.125rem);
  background-color: white;
  overflow-x: hidden;
`;

// Alert is preferred over Snackbar due to more natural custom placement options
// Snackbar is more rigid and provides options only for the edges of the window
const WelcomeBar = styled(Alert)`
  font-weight: 400;
  height: 3.125rem;
  width: 38.375rem;
  background: ${LIABILITY_BLUE}4C;
`;

const SearchSection = styled(Grid)`
  background-color: ${WHITESMOKE};
  padding-left: ${SECTION_PADDING}rem;
  padding-right: ${SECTION_PADDING}rem;
  padding-bottom: 3rem;
`;

const SearchSectionHeader = styled(Typography)`
  color: ${TAS_GREEN};
  padding-top: 3.125rem;
`;

const SearchInputContainer = styled(Grid)`
  margin-right: 2.5rem;
`;

const SearchButton = styled(GreenButton)`
  height: 2.5rem;
  width: 6.375rem;
  margin-top: 2.5rem;
`;

const ResultsSection = styled(Grid)`
  background-color: white;
  height: 100%;
  width: 100%;
  padding-left: ${SECTION_PADDING}rem;
`;

const ResultsTableContainer = styled(TableContainer)`
  margin-top: 2.5rem;
  margin-right: 2.5rem;
`;

const ResultsTableHeadRow = styled(TableRow)`
  background: ${TAS_GREEN}4C; // 4C adds ~30% transparency, as per figma mockup
  border: 1px solid ${SILVER};
`;

const ResultsTableRow = styled(TableRow)`
  border: 1px solid ${SILVER};
`;

const ResultsTableCell = styled(TableCell)`
  width: 9.375rem;
`;

const ResultsTableActionCell = styled(TableCell)`
  width: 10.25rem;
`;

const RegisterButtonRow = styled(Grid)`
  margin-top: 1rem;
`;

/**
 * Patient Search Page, allows users to search for patients, edit them, create appointments for them, and add new patients
 *
 * @returns {React.Element}
 *
 */

const tableColumnHeaders = [
  { id: "lastName", label: "Last Name" },
  { id: "firstName", label: "First Name" },
  { id: "dateOfBirth", label: "DOB" },
  { id: "patientId", label: "Patient ID" },
  { id: "nextAppointment", label: "Next Appointment" },
  { id: "editPatient", label: "" },
  { id: "newAppointment", label: "" },
];

const tableDummyData = [
  {
    lastName: "Stone",
    firstName: "Lime",
    dateOfBirth: "02/04/1985",
    patientId: "0102932",
    nextAppointment: "06/06/2021",
  },
  {
    lastName: "Stone",
    firstName: "Oliver",
    dateOfBirth: "09/15/1946",
    patientId: "0102965",
    nextAppointment: "07/06/2021",
  },
];

const PatientSearch = () => {
  const history = useHistory();
  const keycloak = useKeycloak();

  const [welcomeBarState, setWelcomeBarState] = useState("loading");

  // Form State
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [patientId, setPatientId] = useState("");

  // Results table state
  const [searchResults, setSearchResults] = useState(null);

  const navigateToPage = (targetRoute) => {
    const path = targetRoute;
    history.push(path);
  };

  const handleSearch = () => {
    setSearchResults(tableDummyData);
  };

  // Effects
  useEffect(() => {
    if (welcomeBarState !== "closed") {
      setWelcomeBarState("open");
      setTimeout(() => {
        setWelcomeBarState("closed");
      }, 5000);
    }
  }, [keycloak]); // show welcome bar when keycloak intitializes, close after 5 seconds

  return (
    <Wrapper>
      <SearchSection container spacing={2} data-testid="search-section">
        <Grid item container xs={12} justify="center">
          <Collapse in={welcomeBarState === "open"}>
            <WelcomeBar
              icon={false}
              data-testid="welcome"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setWelcomeBarState("closed")}
                >
                  Dismiss
                </Button>
              }
            >
              Welcome back, {keycloak?.keycloak?.tokenParsed?.given_name}!
            </WelcomeBar>
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <SearchSectionHeader variant="h1">
            Search for Patient
          </SearchSectionHeader>
        </Grid>

        <Grid item container xs={12} justify="flex-start">
          <SearchInputContainer>
            <TextInput
              inputwidth={SEARCH_INPUT_WIDTH}
              label="Last Name"
              labelPlacement="top"
              placeholder=""
              value={lastName}
              setValue={setLastName}
              data-testid="last-name"
            />
          </SearchInputContainer>
          <SearchInputContainer>
            <TextInput
              inputwidth={SEARCH_INPUT_WIDTH}
              label="First Name"
              labelPlacement="top"
              placeholder=""
              value={firstName}
              setValue={setFirstName}
              data-testid="first-name"
            />
          </SearchInputContainer>
          <SearchInputContainer>
            <TextInput
              inputwidth={SEARCH_INPUT_WIDTH}
              label="Date of Birth"
              labelPlacement="top"
              placeholder=""
              value={dateOfBirth}
              setValue={setDateOfBirth}
              data-testid="date-of-birth"
            />
          </SearchInputContainer>
          <SearchInputContainer>
            <TextInput
              inputwidth={SEARCH_INPUT_WIDTH}
              label="Patient ID"
              labelPlacement="top"
              placeholder=""
              value={patientId}
              setValue={setPatientId}
              data-testid="patient-id"
            />
          </SearchInputContainer>

          <Grid item container direction="column" justify="flex-end" xs={1}>
            <SearchButton
              color="secondary"
              onClick={handleSearch}
              size="small"
              variant="outlined"
              data-testid="search-button"
              startIcon={<SearchIcon />}
              width={6.375}
              isDisabled={[firstName, lastName, dateOfBirth, patientId].every(
                (field) => !field.length
              )}
            >
              Search
            </SearchButton>
          </Grid>
        </Grid>
      </SearchSection>

      <ResultsSection container data-testid="results-section">
        <ResultsTableContainer>
          <Table>
            <TableHead>
              <ResultsTableHeadRow>
                {tableColumnHeaders.map((header) => (
                  <TableCell key={header.id}>{header.label}</TableCell>
                ))}
              </ResultsTableHeadRow>
            </TableHead>

            <TableBody>
              {searchResults ? (
                searchResults.map((patient) => (
                  <ResultsTableRow>
                    <ResultsTableCell>{patient.lastName}</ResultsTableCell>
                    <ResultsTableCell>{patient.firstName}</ResultsTableCell>
                    <ResultsTableCell>{patient.dateOfBirth}</ResultsTableCell>
                    <ResultsTableCell>{patient.patientId}</ResultsTableCell>
                    <ResultsTableCell>
                      {patient.nextAppointment}
                    </ResultsTableCell>
                    <ResultsTableActionCell align="left">
                      <IconButton startIcon={<EditIcon />} variant="text">
                        Edit Pt Info
                      </IconButton>
                    </ResultsTableActionCell>
                    <ResultsTableActionCell align="left">
                      <IconButton
                        onClick={() => navigateToPage(appointmentNew)}
                        startIcon={<AddCircleOutlineIcon />}
                        variant="text"
                        customcolor="green"
                      >
                        New Appointment
                      </IconButton>
                    </ResultsTableActionCell>
                  </ResultsTableRow>
                ))
              ) : (
                <ResultsTableRow>
                  <TableCell align="center" colSpan={7}>
                    Please search for a patient to populate results.
                  </TableCell>
                </ResultsTableRow>
              )}
            </TableBody>
          </Table>
          <RegisterButtonRow
            item
            container
            direction="column"
            justify="flex-end"
            xs={1}
          >
            <BlueButton
              color="primary"
              startIcon={<PeopleIcon />}
              type="button"
              variant="outlined"
              width={15}
              data-testid="register-button"
              isDisabled={!searchResults?.length}
              onClick={() => navigateToPage(patientsNew)}
            >
              Register New Patient
            </BlueButton>
          </RegisterButtonRow>
        </ResultsTableContainer>
      </ResultsSection>
    </Wrapper>
  );
};

export default PatientSearch;
