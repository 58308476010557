import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@material-ui/core";
import styled from "styled-components";
import AdditionButton from "./AdditionButton";
import DropDown from "./DropDown";
import TimePicker from "./TimePicker";
import AlternatingTabButton from "./AlternatingTabButton";

// styled component
const ProcedureContainer = styled(Box)`
  padding-top: 1.5rem;
  position: relative;
  background-color: #f5f5f5;
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1.5rem;
`;

const FlexStartRow = styled(Box)`
  position: relative;
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

const SpaceBetweenRow = styled(Box)`
  position: relative;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: inherit;
`;

const TitleText = styled(Typography)`
  color: #2346a7;
  font-weight: bold;
  font-size: 0.9rem;
`;

const SmallSpacer = styled(Box)`
  width: 2rem;
  height: 1px;
  background-color: transparent;
`;

/**
 * Procedure Form Component
 *
 * @param {object} props
 * @param props.procedureObject
 * @param props.index
 * @param props.procedureHook
 * @returns {React.Element}
 */

const ProcedureForm = ({ procedureObject, index, procedureHook }) => {
  const PROCEDURES = ["Procedure 1", "Procedure 2", "Procedure 3"];

  useEffect(() => {
    const procedureState = [...procedureObject];
    const { endTime } = procedureState[index][`Procedure ${index + 1}`];
    if (endTime != null) {
      let dateObj = new Date(endTime);
      const dayPeriod = !procedureState[index][`Procedure ${index + 1}`]
        .alternatingButtonCheck
        ? "AM"
        : "PM";
      const currentHour = dateObj.getHours();

      // current time is in the PM and the AM button is clicked - subtract 12 hours from that time
      if (currentHour >= 12 && dayPeriod === "AM") {
        dateObj = dateObj.setHours(dateObj.getHours() - 12);
        procedureState[index][`Procedure ${index + 1}`].endTime = dateObj;
        procedureHook(procedureState);
      }
      // current hour is in the AM and the PM button is clicked - add 12 hour to that time
      else if (currentHour < 12 && dayPeriod === "PM") {
        dateObj = dateObj.setHours(dateObj.getHours() + 12);
        procedureState[index][`Procedure ${index + 1}`].endTime = dateObj;
        procedureHook(procedureState);
      }
    }
  }, [procedureObject]);

  // function that handles the click events on the different dropdown components - uses switch/case to set the state properly for each one
  const handleChange = (event, type) => {
    const procedureState = [...procedureObject];

    if (type === "procedure") {
      procedureState[index][`Procedure ${index + 1}`].procedure =
        event.target.value;
    }

    procedureHook(procedureState);
  };

  // function that handles the time change on the timepicker component
  const handleTimeChange = (date) => {
    const procedureState = [...procedureObject];
    procedureState[index][`Procedure ${index + 1}`].endTime = date;
    procedureHook(procedureState);
  };

  const getInitialTime = () => {
    let initialDate = new Date();
    initialDate.setHours(0, 0, 0, 0);

    const procedureState = [...procedureObject];
    const dayPeriod = !procedureState[index][`Procedure ${index + 1}`]
      .alternatingButtonCheck
      ? "AM"
      : "PM";

    // if the PM button is pressed before the timepicker is opened, 12 hours are added to the time
    if (dayPeriod === "PM") {
      initialDate = initialDate.setHours(initialDate.getHours() + 12);
    }

    return initialDate;
  };

  // function that sets the state of the current time period (AM or PM) after a time is selected from the picker
  const handleTimeAccept = (date) => {
    const dateObj = new Date(date);
    const hour = dateObj.getHours();
    const procedureState = [...procedureObject];

    if (hour >= 12) {
      procedureState[index][
        `Procedure ${index + 1}`
      ].alternatingButtonCheck = true;
    } else {
      procedureState[index][
        `Procedure ${index + 1}`
      ].alternatingButtonCheck = false;
    }
    procedureHook(procedureState);
  };

  // function that adds a new procedure to the procedure state when it is called
  const addNewProcedure = () => {
    const procedureState = [...procedureObject];
    const keyName = `Procedure ${procedureObject.length + 1}`;
    const newProcedureObject = {
      [keyName]: {
        procedure: "",
        endTime: null,
        alternatingButtonCheck: false,
      },
    };

    procedureState.push(newProcedureObject);
    procedureHook(procedureState);
  };

  return (
    <ProcedureContainer>
      <SpaceBetweenRow>
        <TitleText>Procedure {index + 1}</TitleText>
        {index === 0 && (
          <AdditionButton variant="outlined" onClick={addNewProcedure}>
            Add Another Procedure
          </AdditionButton>
        )}
      </SpaceBetweenRow>
      <FlexStartRow style={{ marginTop: "1.5rem" }}>
        <DropDown
          menuItems={PROCEDURES}
          dropdownType="procedure"
          currentValue={
            procedureObject[index][`Procedure ${index + 1}`].procedure
          }
          label="Procedure"
          onChange={handleChange}
        />
      </FlexStartRow>
      <FlexStartRow style={{ marginTop: "1.5rem" }}>
        <TimePicker
          timeState={procedureObject[index][`Procedure ${index + 1}`].endTime}
          label="End Time"
          onChange={handleTimeChange}
          onAccept={handleTimeAccept}
          initialFocusedDate={getInitialTime}
        />
        <SmallSpacer />
        <AlternatingTabButton
          tags={["AM", "PM"]}
          toggleHook={procedureHook}
          checked={
            procedureObject[index][`Procedure ${index + 1}`]
              .alternatingButtonCheck
          }
          isMapped
          mapObject={procedureObject}
          mapIndex={index}
        />
      </FlexStartRow>
    </ProcedureContainer>
  );
};

ProcedureForm.propTypes = {
  procedureObject: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  procedureHook: PropTypes.func.isRequired,
};

export default ProcedureForm;
